/*OpenSans Font*/

@font-face {
    font-family: 'Opensans';
    src: url('OpenSans-Bold-webfont.eot');
    src: url('OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('OpenSans-Bold-webfont.woff') format('woff'),
         url('OpenSans-Bold-webfont.ttf') format('truetype'),
         url('OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: bold;
    font-style: normal;
}


@font-face {
    font-family: 'Opensans';
    src: url('opensans-light-webfont.eot');
    src: url('opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('opensans-light-webfont.woff') format('woff'),
         url('opensans-light-webfont.ttf') format('truetype'),
         url('opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: 300;
    font-style: normal;

}
@font-face {
    font-family: 'Open Sans';
    src: url('opensans-regular-webfont.eot');
    src: url('opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('opensans-regular-webfont.woff') format('woff'),
         url('opensans-regular-webfont.ttf') format('truetype'),
         url('opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('opensans-light-webfont.eot');
    src: url('opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('opensans-light-webfont.woff') format('woff'),
         url('opensans-light-webfont.ttf') format('truetype'),
         url('opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: 300;
    font-style: normal;

}
@font-face {
    font-family: 'Open Sans';
    src: url('opensans-regular-webfont.eot');
    src: url('opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('opensans-regular-webfont.woff') format('woff'),
         url('opensans-regular-webfont.ttf') format('truetype'),
         url('opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Bold-webfont.eot');
    src: url('OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('OpenSans-Bold-webfont.woff') format('woff'),
         url('OpenSans-Bold-webfont.ttf') format('truetype'),
         url('OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

/*
@font-face {
    font-family: 'OpenSans';
    src: url('opensans-light-webfont.eot');
    src: url('opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('opensans-light-webfont.woff') format('woff'),
         url('opensans-light-webfont.ttf') format('truetype'),
         url('opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: 100;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSans';
    src: url('opensans-regular-webfont.eot');
    src: url('opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('opensans-regular-webfont.woff') format('woff'),
         url('opensans-regular-webfont.ttf') format('truetype'),
         url('opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-Bold-webfont.eot');
    src: url('OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('OpenSans-Bold-webfont.woff') format('woff'),
         url('OpenSans-Bold-webfont.ttf') format('truetype'),
         url('OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-ExtraBold-webfont.eot');
    src: url('OpenSans-ExtraBold-webfont.eot?#iefix') format('embedded-opentype'),
         url('OpenSans-ExtraBold-webfont.woff') format('woff'),
         url('OpenSans-ExtraBold-webfont.ttf') format('truetype'),
         url('OpenSans-ExtraBold-webfont.svg#open_sansextrabold') format('svg');
    font-weight: 900;
    font-style: normal;
}*/