.swiper-container {
  padding-bottom: 60px;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  width: 8px;
  border-radius: 0;
}

.swiper-pagination-bullet-active {

  background: currentColor;
  opacity: 1;
}