a {
  color: #3346d1; }
a:hover {
  color: rgba(51, 70, 209, 0.8); }

.page-header-alpha .navbar-mainmenu .nav-item a:hover {
  color: #3346d1; }

.btn-white {
  color: #ffffff;
  border: 1px solid transparent;
  background-color: #3346d1; }
.btn-white:hover {
  background-color: rgba(51, 70, 209, 0.8);
  color: #ffffff; }

.btn-outline-primary {
  color: #3346d1;
  border-color: currentColor; }

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  color: rgba(51, 70, 209, 0.5);
  border-color: currentColor; }

.btn-transp-arrow.btn-primary.active, .btn-transp-arrow.btn-primary:active,
.btn-transp-arrow.btn-primary:hover {
  background-color: rgba(77, 77, 77, 0.05);
  color: #8b8b8b;
  -webkit-box-shadow: none;
  box-shadow: none; }

/* subscription form */
.form-content-transparent.form-content-white .form-desc h4 {
  color: #ffffff; }

.form-content-transparent.form-content-white .form-input label {
  color: #ffffff; }

.form-control-outline,
.form-control-line {
  border-color: #504c89; }
.form-control-outline:focus,
.form-control-line:focus {
  border-color: #adadad; }

.page-cover:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(-45deg, #c1c1c1, #d4d4d4 100%);
  opacity: 0.5;
}
.media .icon{
  color: #504c89 !important;
  background: transparent !important;
}
.btn-creative:hover{
  color: #504c89!important;
}