.is-error {
  border-color: red;
}

.error_message {
  color: red;
  margin-bottom: 2rem;
  font-weight: bold;
}

.section-content {
  width: 100%;
}
