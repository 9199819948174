.section-group {
  margin-top: 180px;
  margin-bottom: 90px;
  max-width: 800px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    &.menu-visible {
      filter: blur(10px);
    }
  }
}

@media(max-width: 768px) {
  .section-group {
    margin-top: 90px;
    margin-bottom: 45px;
  }
}
